module.exports = () => node => {
  if (node.type === "other-support") {
    return `/other-support/${node.uid}/`
  }
  if (node.type === "page") {
    return `/${node.uid}/`
  }
  if (node.type === "online-lesson") {
    return `/${node.uid}/`
  }
  if (node.type === "top-page") {
    return `/`
  }
  return '/'
}
