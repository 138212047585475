import * as React from "react"
import { withPreviewResolver } from "gatsby-source-prismic"
import linkResolver from "../utils/link-resolver"

import Layout from "../components/layout"
import SEO from "../components/seo"

const PreviewPage = ({ isPreview, isLoading }) => {
  if (isPreview === false) {
    return <div>Not a preview!</div>
  }
  return (
    <Layout>
      <SEO title="preparing preview..." />
      <p>Loading...</p>
    </Layout>
  )
}

const options = {
  repositoryName: process.env.PRISMIC_REPO_NAME,
  linkResolver,
}

export default withPreviewResolver(PreviewPage, options)
